import { forwardRef, useState } from "react";
import השתלות from "../images/services/1.png";
import אסתטיקה from "../images/services/2.png";
import שיקום from "../images/services/3.png";
import משמרת from "../images/services/4.png";
import פנים from "../images/services/5.png";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Services = forwardRef<HTMLDivElement>((props, ref) => {
  const [open1, setOpen1] = useState(false);
  const close1 = () => setOpen1(false);

  const [open2, setOpen2] = useState(false);
  const close2 = () => setOpen2(false);

  const [open3, setOpen3] = useState(false);
  const close3 = () => setOpen3(false);

  const [open4, setOpen4] = useState(false);
  const close4 = () => setOpen4(false);

  const [open5, setOpen5] = useState(false);
  const close5 = () => setOpen5(false);

  return (
    <div className="services-section" ref={ref}>
      <h2 className="title">השירותים שלנו</h2>
      <div className="split-services">
        {/* השתלות */}
        <div className="service-item" onClick={() => setOpen1((o) => !o)}>
          <img className="services-img" src={השתלות} alt="השתלות" />
          <h4 className="mini-title">השתלות</h4>
          <p className="text">
            בעבר, אפשרויות שיקום הפה הגבילו עצמן לתותבות נשלפות או גשרים. בעידן
            המודרני, השתלים הם פתרון מתקדם שאינו פוגע בשלמות ובבריאות השיניים
            הסמוכות. השתלים מספקים יציבות, בטיחות, שדרוג בתפקוד הפה, ומשפרים את
            איכות חייו של המטופל
          </p>
        </div>
        <Popup
          contentStyle={{
            borderRadius: "30px",
            width: "90%",
          }}
          open={open1}
          modal
          lockScroll
          closeOnDocumentClick={false}
        >
          <IconButton onClick={close1}>
            <CloseIcon />
          </IconButton>
          <div className="popup-item">
            <img className="services-img" src={השתלות} alt="השתלות" />
            <h4 className="mini-title">השתלות</h4>
            <p className="text" style={{ marginBottom: "0.4em" }}>
              אם בעבר אפשרויות שיקום הפה היו בעזרת תותבות נשלפות או גשרים על גבי
              השיניים , כיום השתלים מהווים פתרון מצויין , ללא פגיעה בשיניים
              שלמות ובריאות. למעשה שתל מהווה תחליף לשורש השן החסרה, שעליו בשלב
              מאוחר יותר יותקן הכתר הסופי, להשלמת שן אחת ועד לפה מלא ללא פגיעה
              בשיניים הסמוכות.בעזרת שתלים ניתן גם לעשות שיקום עם תותבות נשלפות
              כאשר השתלים משמשים בסיס ליציבות התותבת. דר בורוכוב ברוך בעל נסיון
              של מעל 29 שנה מבצע את כל סוגי ההשתלות במטרה להביא את המטופל למצב
              אידיאלי תפקודי ואסתטי
            </p>
            <h4 className="mini-title">מה הוא שתל?</h4>
            <p className="text">
              שתל למעשה גוף מתכתי שלרוב נראה כמו בורג. הקיבוע של השתל אל עצם
              הלסת נעשה בדרך כלל בתנועת הברגה. השתל מוכנס לגמרי פנימה, כך שהקצה
              שלו לא בולט מעל לחניכיים. כמות ואיכות העצם באיזור הינה בעלת חשיבות
              רבה קליטת השתל. במקרים בהם ישנו מחסור בעצם באיזור ניתן לבצע השתלת
              עצם. במקרים של חסר עצם אנו מבצעים השתלות עצם ובניה, הרמת סינוס
              בשיטה סגורה / פתוחה, בהתאם לצורך.
            </p>
          </div>
        </Popup>

        {/* אסתטיקה דנטלית */}
        <div className="service-item" onClick={() => setOpen2((o) => !o)}>
          <img className="services-img" src={אסתטיקה} alt="אסתטיקה דנטלית" />
          <h4 className="mini-title">אסתטיקה דנטלית</h4>
          <p className="text">
            ציפויים דנטליים הם פתרון פופולרי לשדרוג ושיפור המראה הכללי של
            השיניים. הם מתקנים חסרונות אסתטיים, כגון שיניים בולטות או פגועות,
            ומשדרגים את המראה של השיניים. בחירת הציפוי תלויה בהעדפות וצרכי
            המטופל.
          </p>
        </div>

        <Popup
          contentStyle={{
            borderRadius: "30px",
            width: "90%",
          }}
          open={open2}
          modal
          lockScroll
          closeOnDocumentClick={false}
        >
          <IconButton onClick={close2}>
            <CloseIcon />
          </IconButton>{" "}
          <div className="popup-item">
            <img className="services-img" src={אסתטיקה} alt="אסתטיקה דנטלית" />
            <h4 className="mini-title">
              אסתטיקה דנטלית <br></br>ציפויי חרסינה/ציפויי קומפטזיט
            </h4>
            <p className="text" style={{ marginBottom: "0.4em" }}>
              ציפוי חרסינה – למינייט- הוא למעשה ציפוי דק כעלה, לאחר השחזה
              מינימלית של השן הוא מודבק לחזית השן. ציפוי קומפוזיט – ציפוי על
              חזית השן שמבוצע במרפאה על ידי הרופא ללא צורך בטכנאי, מחומר
              קומפוזיט ( חומר שבעזרתו מבצעים סתימות לבנות). ציפויים אלו מבצעים
              כמעט ללא השחזת שן המטופל.
            </p>
            <h4 className="mini-title">יתרונות וחסרונות של ציפוי חרסינה</h4>
            <p className="text" style={{ marginBottom: "0.4em" }}>
              {" "}
              בציפויי חרסינה יש להוריד מהשן בהשחזה לא מעט, דבר שיכול להוביל
              לטיפול שורש בעתיד. ציפויי חרסינה לא משנים את צבען לאורך זמן. מחירו
              יקר יותר, ובמקרה של שבר בציפוי יש להחליף את כולו.
            </p>
            <h4 className="mini-title">יתרונות וחסרונות של ציפוי קומפוזיט</h4>
            <p className="text">
              השחזה והורדת חומר שן מינימלית עד אפסית. נדיר מאוד שיש נזק לשן.
              צבעו עלול לדהות עם הזמן, תיקון/שחזור חוזר קל לביצוע תוך זמן קצר
              במרפאה. עלותו זולה בהרבה מציפויי חרסינה.
            </p>
          </div>
        </Popup>

        {/* שיקום הפה */}
        <div className="service-item" onClick={() => setOpen3((o) => !o)}>
          <img className="services-img" src={שיקום} alt="שיקום הפה" />
          <h4 className="mini-title">שיקום הפה</h4>
          <p className="text">
            כתרי זירקוניה הם פתרון לשיניים חסרות או פגועות. זירקוניה הוא חומר
            המתאים בצורה טבעית לסביבת השן. התיאום הביולוגי שלו מספק יעילות ארוכת
            טווח, ואף על פי שהמחיר יכול להיות גבוה, הוא מספק יתרונות רבים באיכות
            ונוחות הטיפול.
          </p>
        </div>
        <Popup
          contentStyle={{
            borderRadius: "30px",
            width: "90%",
          }}
          open={open3}
          modal
          lockScroll
          closeOnDocumentClick={false}
        >
          <IconButton onClick={close3}>
            <CloseIcon />
          </IconButton>{" "}
          <div className="popup-item">
            <img className="services-img" src={שיקום} alt="שיקום הפה" />
            <h4 className="mini-title">
              שיקום הפה <br />
              כתרי זירקוניה
            </h4>
            <p className="text" style={{ marginBottom: "0.4em" }}>
              כתר על שן / שתל הינו פתרון שיקומי לשיניים שבורות או חסרות. את הכתר
              "מלבישים" על השן שעברה השחזה והקטנה לצורך התאמה מושלמת. ישנם סוגים
              שונים של כתרים .כתר זירקוניה הוא אחד מהם.
            </p>
            <h4 className="mini-title">יתרונות של כתרי זירקוניה</h4>
            <p className="text" style={{ marginBottom: "0.4em" }}>
              {" "}
              חזק מאוד ועמיד בלחצים ועומסים אסתטית הינו ראשון במעלה, מאפשר חיבור
              עם חרסינה בחזית השן בצורה אסתטית ביותר. והכי חשוב ! מכיוון שהוא
              חומר טבעי הוא גם ביוקומפטיבילי , כלומר תואם ביולוגית לחכיניים
              ולסביבת השן. מה שמקנה לו אורך חיים של שנים מחירו יקר יותר .
            </p>
          </div>
        </Popup>

        {/* רפואת שיניים משמרת */}
        <div className="service-item" onClick={() => setOpen4((o) => !o)}>
          <img className="services-img" src={משמרת} alt="רפואת שיניים משמרת" />
          <h4 className="mini-title">רפואת שיניים משמרת</h4>
          <p className="text">
            במרפאתינו מבוצעים גם טיפולי שמירה ושיקום. תחום השיקום כולל סתימות,
            שחזורים וטיפולי שורש מקיפים. כמו כן, אנו מציעים פתרונות מתקדמים כמו
            אונליי לשמירה על שיניים שבורות ושיפור יכולתן.
          </p>
        </div>

        <Popup
          contentStyle={{
            borderRadius: "30px",
            width: "90%",
          }}
          open={open4}
          modal
          lockScroll
          closeOnDocumentClick={false}
        >
          <IconButton onClick={close4}>
            <CloseIcon />
          </IconButton>{" "}
          <div className="popup-item">
            <img
              className="services-img"
              src={משמרת}
              alt="רפואת שיניים משמרת"
            />
            <h4 className="mini-title">רפואת שיניים משמרת</h4>
            <p className="text" style={{ marginBottom: "0.8em" }}>
              השימור והשיקום של השיניים הוא מרכזי וחשוב לנו, ולכן אנו מציעים
              מגוון רחב של טיפולים המתאימים לצרכי הטיפול של כל לקוח. הנה עקרונות
              הטיפולים המרכזיים שאנו מציעים:
            </p>
            <h4 className="mini-title">סתימות ושחזורים:</h4>
            <p className="text" style={{ marginBottom: "0.8em" }}>
              אנו מבצעים סתימות ושחזורים מקצועיים ואיכותיים מכל הסוגים, המתאימים
              לצורך שימור ושיקום השיניים.
            </p>
            <h4 className="mini-title">טיפולי שורש:</h4>
            <p className="text" style={{ marginBottom: "0.8em" }}>
              הטיפולים המתקדמים ביותר בטיפולי שורש כוללים ניקוי אבן רגיל ועמוק,
              כולל טיפול חניכיים עם צ'יפים תת חניכיים. טיפול זה מסייע בשמירה על
              בריאות השיניים ובמניעת בעיות פוטנציאליות.
            </p>
            <h4 className="mini-title">השלמת שיניים שבורות:</h4>
            <p className="text" style={{ marginBottom: "0.8em" }}>
              אנו מציעים אפשרויות לשיקום שיניים שבורות בצורה אסתטית ותפקודית. זה
              כולל ציפויי קומפוזיט או חרסינה להשלמת שיניים שבורות קדמיות
              ואחוריות.
            </p>
            <h4 className="mini-title">אונליי:</h4>
            <p className="text" style={{ marginBottom: "0.8em" }}>
              פתרון מתקדם לשמירה על שיניים שבורות, הכולל סתימה המשחזרת את כוחה
              ואת יכולתה של השן. אונליי מיוצר מחרסינה מיוחדת וחזקה ביותר, ומחובר
              לשן באמצעות דבק חזק מאוד. הטיפול באונליי מסייע להציל את השן מטיפול
              שורש ומספק תוצאות גבוהות של הצלחה.{" "}
            </p>
          </div>{" "}
        </Popup>

        {/* אסתטיקת פנים */}
        <div className="service-item" onClick={() => setOpen5((o) => !o)}>
          <img className="services-img" src={פנים} alt="אסתטיקת פנים" />
          <h4 className="mini-title">אסתטיקת פנים</h4>
          <p className="text">
            במרפאתינו, אנו מציעים הזרקות בוטוקס וחומצה היאלורונית לשיפור מראה
            העור והפנים. בוטוקס הוא טיפול קוסמטי המחזיר לעור מראה חלק וצעיר
            בצורה זמנית. חומצה היאלורונית היא חומר טבעי המשמש להחלקת העור ומילוי
            הקמטים והקמטוטים בפנים.
          </p>
        </div>

        <Popup
          contentStyle={{
            borderRadius: "30px",
            width: "90%",
          }}
          open={open5}
          modal
          lockScroll
          closeOnDocumentClick={false}
        >
          <IconButton onClick={close5}>
            <CloseIcon />
          </IconButton>
          <div className="popup-item">
            <img className="services-img" src={פנים} alt="אסתטיקת פנים" />
            <h4 className="mini-title">אסתטיקת פנים</h4>
            <p className="text" style={{ marginBottom: "0.8em" }}>
              במרפאתינו אנו מציעים טיפולי בוטוקס וחומצה היאלורונית עבור השלמת
              המראה האסתטי המלא.
            </p>
            <h4 className="mini-title">בוטוקס</h4>
            <p className="text" style={{ marginBottom: "0.8em" }}>
              בוטוקס הוא רעלן של חיידק שתפקידו לשתק את השרירים באופן זמני, מה
              שמחליק קמטים באזורים כמו המצח וצידי העיניים ועוד.
            </p>
            <h4 className="mini-title">חומצה היאלורונית</h4>
            <p className="text" style={{ marginBottom: "0.8em" }}>
              חומצה היאלורונית היא חומר טבעי שמיוצר בגוף ומשמש להחלקת העור
              ומילוי הקמטים והקמטוטים בפנים. ההזרקה של חומר זה מקנה נפח לאיזורים
              מדולדלים ונפולים, והשפעתו זמנית למשך כ-4-6 חודשים.
            </p>
          </div>
        </Popup>
      </div>
    </div>
  );
});

export default Services;
