import { forwardRef } from "react";

const Contact = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div ref={ref} className="bg-light-blue">
      <div className="contact-div">
        <h2 className="title">
          יצירת קשר
        </h2>
        <p className="p-contact">
          מרפאת שיניים של דר בורוכוב ברוך בעל נסיון של 27 שנה בתחום, מרפאתנו
          ביתית ומשפחתית מציעה טיפולי שיניים מורכבים כגון השתלות שיניים, טיפולי
          אסתטיקה דנטלית וכל סוגי שיקום הפה (כתרי חרסינה וזרקוניה, שיניים תותבות
          ,וכדומה). כמו כן ד"ר בורוכוב מבצע הזרקות בוטוקס ומילוי חומצה היאלורונית
          כטיפול משלים לאסתטיקת הפנים. מרפאתינו מלווה את לקוחותינו לאורך כל
          הטיפול, עם זמינות גבוהה, מענה טלפוני והודעות ואטסאפ
        </p>

        <div className="contant-split">
          <div
            className="contact-card"
            onClick={() => window.open("tel:+97236515839")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 100 100"
            >
              <g
                id="Group_17"
                data-name="Group 17"
                transform="translate(-1715 -2325)"
              >
                <rect
                  id="Rectangle_14"
                  data-name="Rectangle 14"
                  width="100"
                  height="100"
                  rx="50"
                  transform="translate(1715 2325)"
                  fill="#c7e4f6"
                />
                <g id="phone_black_24dp" transform="translate(1735 2345)">
                  <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M0,0H60V60H0Z"
                    fill="none"
                  />
                  <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M12.05,22.475A37.872,37.872,0,0,0,28.525,38.95l5.5-5.5a2.486,2.486,0,0,1,2.55-.6A28.518,28.518,0,0,0,45.5,34.275a2.507,2.507,0,0,1,2.5,2.5V45.5A2.507,2.507,0,0,1,45.5,48,42.5,42.5,0,0,1,3,5.5,2.507,2.507,0,0,1,5.5,3h8.75a2.507,2.507,0,0,1,2.5,2.5,28.4,28.4,0,0,0,1.425,8.925,2.509,2.509,0,0,1-.625,2.55Z"
                    transform="translate(4.5 4.5)"
                    fill="#345365"
                  />
                </g>
              </g>
            </svg>

            <p className="p-contact-card">03-6515839</p>
          </div>

          <div
            className="contact-card"
            onClick={() => window.open("mailto:baruchborohov@gmail.com")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 100 100"
            >
              <g
                id="Group_18"
                data-name="Group 18"
                transform="translate(-1514 -2325)"
              >
                <rect
                  id="Rectangle_15"
                  data-name="Rectangle 15"
                  width="100"
                  height="100"
                  rx="50"
                  transform="translate(1514 2325)"
                  fill="#c7e4f6"
                />
                <g
                  id="alternate_email_black_24dp"
                  transform="translate(1534 2345)"
                >
                  <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M0,0H60V60H0Z"
                    fill="none"
                  />
                  <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M27,2a25,25,0,0,0,0,50H39.5V47H27A20.256,20.256,0,0,1,7,27,20.256,20.256,0,0,1,27,7,20.256,20.256,0,0,1,47,27v3.575A4.041,4.041,0,0,1,43.25,34.5a4.041,4.041,0,0,1-3.75-3.925V27a12.5,12.5,0,1,0-3.65,8.825,9.258,9.258,0,0,0,7.4,3.675A8.782,8.782,0,0,0,52,30.575V27A25.009,25.009,0,0,0,27,2Zm0,32.5A7.5,7.5,0,1,1,34.5,27,7.49,7.49,0,0,1,27,34.5Z"
                    transform="translate(3 3)"
                    fill="#345365"
                  />
                </g>
              </g>
            </svg>

            <p className="p-contact-card">baruchborohov@gmail.com</p>
          </div>

          <div
            className="contact-card"
            onClick={() =>
              window.open(
                "https://www.facebook.com/profile.php?id=100054260270359&mibextid=ZbWKwL"
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 100 100"
            >
              <g
                id="Group_19"
                data-name="Group 19"
                transform="translate(-1514 -2325)"
              >
                <rect
                  id="Rectangle_15"
                  data-name="Rectangle 15"
                  width="100"
                  height="100"
                  rx="50"
                  transform="translate(1514 2325)"
                  fill="#c7e4f6"
                />
                <path
                  id="Icon_awesome-facebook"
                  data-name="Icon awesome-facebook"
                  d="M45.562,23.2A22.5,22.5,0,1,0,19.547,45.569V29.747H13.831V23.2h5.716V18.215c0-5.674,3.357-8.808,8.5-8.808a34.419,34.419,0,0,1,5.037.442v5.569H30.244c-2.794,0-3.666,1.745-3.666,3.536V23.2h6.24l-1,6.545H26.578V45.569A22.606,22.606,0,0,0,45.562,23.2Z"
                  transform="translate(1540.938 2351.934)"
                  fill="#345365"
                />
              </g>
            </svg>

            <p className="p-contact-card">דף הפייסבוק שלנו</p>
          </div>

          <div
            className="contact-card"
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send/?phone=972586455011&text=%D7%90%D7%A9%D7%9E%D7%97+%D7%9C%D7%A7%D7%91%D7%95%D7%A2+%D7%AA%D7%95%D7%A8+%D7%9C%D7%9E%D7%A8%D7%A4%D7%90%D7%94%2C+%D7%AA%D7%95%D7%93%D7%94&type=phone_number&app_absent=0"
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 100 100"
            >
              <g
                id="Group_16"
                data-name="Group 16"
                transform="translate(-1514 -2325)"
              >
                <rect
                  id="Rectangle_15"
                  data-name="Rectangle 15"
                  width="100"
                  height="100"
                  rx="50"
                  transform="translate(1514 2325)"
                  fill="#c7e4f6"
                />
                <path
                  id="Icon_awesome-whatsapp"
                  data-name="Icon awesome-whatsapp"
                  d="M38.26,8.789A22.306,22.306,0,0,0,3.164,35.7L0,47.25l11.823-3.1A22.231,22.231,0,0,0,22.48,46.858h.01A22.514,22.514,0,0,0,45,24.559a22.387,22.387,0,0,0-6.74-15.77ZM22.49,43.1a18.5,18.5,0,0,1-9.442-2.581l-.673-.4L5.364,41.956l1.868-6.84-.442-.7a18.57,18.57,0,1,1,34.443-9.854A18.741,18.741,0,0,1,22.49,43.1ZM32.655,29.22c-.552-.281-3.295-1.627-3.807-1.808s-.884-.281-1.256.281-1.436,1.808-1.768,2.19-.653.422-1.205.141c-3.275-1.637-5.424-2.923-7.584-6.629-.573-.984.573-.914,1.637-3.044a1.033,1.033,0,0,0-.05-.974c-.141-.281-1.256-3.023-1.718-4.138-.452-1.085-.914-.934-1.256-.954-.321-.02-.693-.02-1.065-.02a2.064,2.064,0,0,0-1.487.693,6.261,6.261,0,0,0-1.949,4.651c0,2.742,2,5.394,2.27,5.766s3.927,6,9.522,8.417c3.536,1.527,4.922,1.657,6.69,1.4,1.075-.161,3.295-1.346,3.757-2.652a4.66,4.66,0,0,0,.321-2.652C33.579,29.632,33.208,29.491,32.655,29.22Z"
                  transform="translate(1542 2350.75)"
                  fill="#345365"
                />
              </g>
            </svg>
            <p className="p-contact-card">058-6455011</p>
          </div>

          <div
            className="contact-card"
            onClick={() =>
              window.open("https://maps.app.goo.gl/8RXJ4Ef28T3mjtJS8")
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 100 100"
            >
              <g
                id="Group_20"
                data-name="Group 20"
                transform="translate(-1514 -2325)"
              >
                <rect
                  id="Rectangle_15"
                  data-name="Rectangle 15"
                  width="100"
                  height="100"
                  rx="50"
                  transform="translate(1514 2325)"
                  fill="#c7e4f6"
                />
                <g id="room_black_24dp" transform="translate(1534 2345)">
                  <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M0,0H60V60H0Z"
                    fill="none"
                  />
                  <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M22.5,2A17.487,17.487,0,0,0,5,19.5C5,32.625,22.5,52,22.5,52S40,32.625,40,19.5A17.487,17.487,0,0,0,22.5,2Zm0,23.75a6.25,6.25,0,1,1,6.25-6.25A6.252,6.252,0,0,1,22.5,25.75Z"
                    transform="translate(7.5 3)"
                    fill="#345365"
                  />
                </g>
              </g>
            </svg>
            <p className="p-contact-card">סוקולוב 12 חולון</p>
          </div>

          <div
            className="contact-card"
            onClick={() =>
              window.open("https://www.youtube.com/@dr-borohov-baruch/featured")
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100"
              viewBox="0 0 100 100"
            >
              <g
                id="Group_21"
                data-name="Group 21"
                transform="translate(-1514 -2325)"
              >
                <rect
                  id="Rectangle_15"
                  data-name="Rectangle 15"
                  width="100"
                  height="100"
                  rx="50"
                  transform="translate(1514 2325)"
                  fill="#c7e4f6"
                />
                <g
                  id="smart_display_black_24dp"
                  transform="translate(1534 2345)"
                >
                  <g id="Group_22" data-name="Group 22">
                    <rect
                      id="Rectangle_16"
                      data-name="Rectangle 16"
                      width="60"
                      height="60"
                      fill="none"
                    />
                  </g>
                  <g
                    id="Group_23"
                    data-name="Group 23"
                    transform="translate(5 10)"
                  >
                    <path
                      id="Path_17"
                      data-name="Path 17"
                      d="M47,4H7A5.015,5.015,0,0,0,2,9V39a5.015,5.015,0,0,0,5,5H47a5.015,5.015,0,0,0,5-5V9A5.015,5.015,0,0,0,47,4ZM20.75,35.25V12.75L38.25,24Z"
                      transform="translate(-2 -4)"
                      fill="#345365"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <p className="p-contact-card">ערוץ היוטיוב שלנו</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Contact;
