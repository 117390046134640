const GoodThings = () => {
  return (
    <div className="bg-light-blue">
      <div className="line-background">
        <div className="good-things-div split-good-things">
          <div className="good-things-card">
            <svg
              className="icon-good-things"
              id="emoji_events_black_24dp"
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              viewBox="0 0 60 60"
            >
              <rect
                id="Rectangle_13"
                data-name="Rectangle 13"
                width="60"
                height="60"
                fill="none"
              />
              <path
                id="Path_8"
                data-name="Path 8"
                d="M43,8H38V3H13V8H8a5.015,5.015,0,0,0-5,5v2.5A12.479,12.479,0,0,0,13.975,27.85,12.523,12.523,0,0,0,23,35.25V43H13v5H38V43H28V35.25a12.523,12.523,0,0,0,9.025-7.4A12.479,12.479,0,0,0,48,15.5V13A5.015,5.015,0,0,0,43,8ZM8,15.5V13h5v9.55A7.526,7.526,0,0,1,8,15.5Zm35,0a7.526,7.526,0,0,1-5,7.05V13h5Z"
                transform="translate(4.5 4.5)"
                fill="#345365"
              />
            </svg>

            <h3 className="mini-title">27 שנים בתחום</h3>
            <p className="text">
              עם ניסיון רב, מיומנויות ודיוק, הן בטיפולי השיניים והן בטיפולים
              האסטטיים
            </p>
          </div>

          <div className="good-things-card">
            <svg
              className="icon-good-things"
              id="diversity_1_black_24dp"
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              viewBox="0 0 60 60"
            >
              <g id="Group_10" data-name="Group 10">
                <rect
                  id="Rectangle_12"
                  data-name="Rectangle 12"
                  width="60"
                  height="60"
                  fill="none"
                />
              </g>
              <g id="Group_12" data-name="Group 12" transform="translate(0 5)">
                <g id="Group_11" data-name="Group 11">
                  <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="5"
                    cy="5"
                    r="5"
                    transform="translate(5 25)"
                    fill="#345365"
                  />
                  <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M3.05,18.45A5.027,5.027,0,0,0,0,23.075V27H11.25V22.975a11.247,11.247,0,0,1,1.575-5.725A17.457,17.457,0,0,0,10,17,17.376,17.376,0,0,0,3.05,18.45Z"
                    transform="translate(0 20.5)"
                    fill="#345365"
                  />
                  <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="5"
                    cy="5"
                    r="5"
                    transform="translate(45 25)"
                    fill="#345365"
                  />
                  <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M28.645,18.45a17,17,0,0,0-9.775-1.2,11.247,11.247,0,0,1,1.575,5.725V27h11.25V23.075A5.027,5.027,0,0,0,28.645,18.45Z"
                    transform="translate(28.305 20.5)"
                    fill="#345365"
                  />
                  <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M31.6,18A26.108,26.108,0,0,0,21,15.75,26.527,26.527,0,0,0,10.4,18,7.47,7.47,0,0,0,6,24.85v4.025H36V24.85A7.47,7.47,0,0,0,31.6,18Z"
                    transform="translate(9 18.625)"
                    fill="#345365"
                  />
                  <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M9,16.5A7.5,7.5,0,1,0,16.5,9,7.49,7.49,0,0,0,9,16.5Z"
                    transform="translate(13.5 8.5)"
                    fill="#345365"
                  />
                  <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M6.2,24.15A14.805,14.805,0,0,1,5,18.5,11.376,11.376,0,0,1,16.5,7c6.7,0,9.55,4.35,13.5,8.975C33.925,11.4,36.75,7,43.5,7A11.376,11.376,0,0,1,55,18.5a14.805,14.805,0,0,1-1.2,5.65,8.731,8.731,0,0,1,3.825,3.6A20.51,20.51,0,0,0,60,18.5,16.327,16.327,0,0,0,43.5,2,17.936,17.936,0,0,0,30,8.275,17.936,17.936,0,0,0,16.5,2,16.327,16.327,0,0,0,0,18.5a20.225,20.225,0,0,0,2.4,9.25A8.805,8.805,0,0,1,6.2,24.15Z"
                    transform="translate(0 -2)"
                    fill="#345365"
                  />
                </g>
              </g>
            </svg>

            <h3 className="mini-title">יחס חם ואישי</h3>
            <p className="text">
              מרפאת הבוטיק המשפחתית שלנו נותנת שירות לכולם, ביחס אישי וחם
            </p>
          </div>

          <div className="good-things-card">
            <svg
              className="icon-good-things"
              id="gpp_good_black_24dp"
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              viewBox="0 0 60 60"
            >
              <g id="Group_13" data-name="Group 13">
                <path
                  id="Path_9"
                  data-name="Path 9"
                  d="M0,0H60V60H0Z"
                  fill="none"
                />
              </g>
              <g id="Group_14" data-name="Group 14" transform="translate(10 5)">
                <path
                  id="Path_10"
                  data-name="Path 10"
                  d="M24,2,4,9.5V24.725C4,37.35,12.525,49.125,24,52c11.475-2.875,20-14.65,20-27.275V9.5ZM21.35,35.85,12.5,27l3.525-3.525,5.3,5.3,10.6-10.6L35.45,21.7Z"
                  transform="translate(-4 -2)"
                  fill="#345365"
                />
              </g>
            </svg>

            <h3 className="mini-title">מקצועיות ואמינות</h3>
            <p className="text">
              על כל הטיפולים שלנו יש אחריות, גם בזמן הטיפול וכמובן גם אחריו
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoodThings;
