import { forwardRef } from "react";
import work from "../images/work.jpg";

const About = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div className="bg-light-blue">
      <div
        ref={ref}
        className="about-split"
        style={{ maxWidth: "1905px", margin: "0 auto" }}
      >
        <article className="text-about-div">
          <h2 className="text-blue title-about">מרפאת שיניים ד"ר בורוכוב</h2>
          <p className="text-about">
            מרפאת השיניים של ד"ר בורוכוב ברוך, בעל נסיון של 27 שנה בתחום.
            מרפאתינו ביתית ומשפחתית, מציעה טיפולי שיניים מורכבים, כגון: השתלות
            שיניים ,טיפולי אסתטיקה דנטלית וכל סוגי שיקום הפה (כתרי חרסינה
            וזרקוניה, שיניים תותבות וכדומה).
          </p>
          <p className="text-about">
            כמו כן ד"ר בורוכוב מבצע הזרקות בוטוקס ומילוי חומצה היאלורונית כטיפול
            משלים לאסתטיקת פנים.
          </p>
          <p className="text-about">
            מרפאתינו מלווה את לקוחותינו לאורך כל הטיפול עם זמינות גבוהה, מענה
            טלפוני והודעות וואטסאפ
          </p>
          <p className="text-about">כתובתינו סוקולוב 12 חולון, לרשות המטופלים ישנה חניה לרכב פרטי.</p>
          <p className="text-about">מספר הטלפון של המרפאה: 03-6515839</p>
          <p className="text-about">מספר הוואטסאפ של המרפאה: 058-6455011</p>
          <p className="text-about">מייל: baruchborohov@gmail.com</p>
          <p className="text-about">שעות וימי עבודה: א - ד</p>
          <p className="text-about"> 18:30 - 15:00 | 09:00-14:00</p>
        </article>

        <div className="video-div">
          <div className="video">
            <iframe 
              src="https://www.youtube.com/embed/BvKBb4Ap91A?si=0-6a9FsZbTCiz4qR"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "2em" }}>
        <img className="img-work" src={work} alt="דוקטור ברוך בטיפול" />
      </div>
    </div>
  );
});

export default About;
