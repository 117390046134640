import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { forwardRef } from "react";

const Comments = forwardRef<HTMLDivElement>((props, ref) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 15000,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: true,
    centerMode: true,
    centerPadding: "23%",
    responsive: [
      {
        breakpoint: 900,
        settings: {
          centerPadding: "0%",
        },
      },
    ],
  };

  return (
    <div ref={ref} className="comments-div">
      <h2 className="title">לקוחות מספרים</h2>
      <Slider {...settings}>
        <div className="card-comment">
          <p className="name-comment">דקל ר</p>
          <p className="text-comment">
            דר ברוך היקר! לאחר שעברנו כברת דרך ארוכה ומורכבת אני יכול לומר
            בסיפוק רב שאין כמוך!! יש לי הערכה רבה למקצועיות שלך, להתייחסות
            האנושית והאישית, לאמפתיה ולנאמנות שלך למקצוע ולמטופל. זכיתי להכיר
            רופא שיניים משכמו ומעלה, הן כאיש מקצוע והן כאדם. אני מודה לך מקרב לב
            על הכל ובעיקר על היכולת שלך ליצור בי אימון, ביטחון ושלווה. בתודה
            ובברכה
          </p>
        </div>

        <div className="card-comment">
          <p className="name-comment">הודיה ד</p>
          <p className="text-comment">
            רצית לומר תודה מקרב לב על טיפול מסור ביותר, ומעבר לזה מקצועי ברמה
            גבוהה, רופא שיודע מה הוא עושה. קליניקה נקייה ומסודרת, אין המתנה, כל
            אחד בתורו בטיפול אישי. הייתה לי חוויה טובה מטיפול לא פשוט, כל הפחד
            שליווה אותי התקבל באהבה וקיבלתי ביטחון מהרופא ומהצוות. הרופא ליווה
            אותי במהלך כל הדרך לאחר הניתוח הן טלפונית והן בביקורים. אין לי מילים
            להודות לרופא שללא ספק הוא שליח טוב! מומלץ מאוד באהבה גדולה.
          </p>
        </div>

        <div className="card-comment">
          <p className="name-comment">רחל פ</p>
          <p className="text-comment">
            תודה לד"ר ברוך על טיפול מקצועי. רופא נגיש, עדין ואפילו מלווה בשירים
            נעימים. תודה על אוירה נעימה, משפחתית ואיכפתית, ישר כח.
          </p>
        </div>

        <div className="card-comment">
          <p className="name-comment">נטלי ק</p>
          <p className="text-comment">
            לד"ר ברוך! תודה על הסבלנות, אכפתיות, מקצועיות ויחס חם! מאוד מרוצה
            מהטיפול! אמליץ עליך לכל אהוביי! תודה מכל הלב.
          </p>
        </div>

        <div className="card-comment">
          <p className="name-comment">נעמה ק</p>
          <p className="text-comment">
            שנים טופלתי אצל רופאת השיניים שלי. עד הרגע בו קיבלתי הצעת מחיר לא
            הגיונית. זה היה השלב בו קיבלתי המלצה על ד"ר ברוך בורוכוב. וכשמו כך
            הברכה המצויה בעבודתו. איש נעים הליכות, מקצועי ומיומן, עונה בסבלנות
            אין קץ לכל שאלה, זמין ואנושי. הקליניקה נעימה לעין ונקיה, הצוות שעובד
            עימו מעניק תחושה נפלאה שהגעתם למקום הנכון. ממליצה בכל לבי על הד"ר.
          </p>
        </div>

        <div className="card-comment">
          <p className="name-comment">אריה ב</p>
          <p className="text-comment">
            ד"ר בורוכוב היקר, הגעתי אליך בדקה ה-91 מבחינת מצב השיניים. ישבת איתי
            עם הרבה סבלנות וסובלנות. ניתחת את מצבי, הצעת לי כמה פתרונות אפשריים.
            עם הרבה אמונה ביכולתך, התחלתי בתהליך ארוך של אוברול כללי לשיניים
            שלי. השבוע ביקרתי במרפאתך למטרת ביקורת לאחר גמר הטיפול לפני כחצי שנה
            והגיע זמן שאגיד לך תודה רבה על המקצועיות, על שירות עדין, אדיב
            ומקצועי. על הסבלנות ובעיקר על המחיר והגמישות בתנאי התשלום. תודה רבה!
          </p>
        </div>

        <div className="card-comment">
          <p className="name-comment">רבקה</p>
          <p className="text-comment">
            ד"ר יקר, רציתי להגיד תודה רבה על הטיפול המסור הסבלני והמקצועי. אין
            רופאים מקצועיים כמוך, ושירות מעל המצופה. תודה על האנושיות שבכם, אשמח
            אם תוכלו לפרסם את ההמלצה שלי, באהבה :)
          </p>
        </div>

        <div className="card-comment">
          <p className="name-comment">רחל א</p>
          <p className="text-comment">
            ראשית ד"ר ברוך אנושי, אמפתי, מקצועי, סבלני ויסודי. אני מאוד שמחה
            שעברתי את כל הטיפולים שלי אצל ד"ר ברוך. כן ירבו רופאים אנושיים
            ומקצועיים כמותו🙏🙏❤️
          </p>
        </div>

        <div className="card-comment">
          <p className="name-comment">אסתר א</p>
          <p className="text-comment">
            ד"ר ברוך. אני שוב פעם רוצה להודות על ההשתלות. מאוד חששתי ופחדתי. ב"ה
            הרגשתי שאני ממש בידיים טובות, מנוסות ומסורות. ההשתלה עצמה לא כאבה,
            עברה מהר. אני ממש ממש מודה לך. מרגישה שבחרתי ברופא הכי טוב בשבילי.
            תודה רבה מעריכה מאוד.
          </p>
        </div>

        <div className="card-comment">
          <p className="name-comment">אשר ס</p>
          <p className="text-comment">
            ברצוני לשבח מאוד את ד"ר ברוך בורוכוב היקר ואת צוות המרפאה. אני מטופל
            של ד"ר ברוך כמעט 20 שנה ובכל פעם אני נדהם מחדש מהיחס האישי, הטיפול
            המסור וההרגשה הביתית שאני מקבל במרפאה. המחיר הוגן והכול בחיוך
            ובצניעות! ירבו כמותכם בישראל. בכבוד רב
          </p>
        </div>
      </Slider>
    </div>
  );
});

export default Comments;
