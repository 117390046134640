import { useRef } from "react";
import "./App.css";
import logo from "./images/logo.png";
import Welcome from "./components/Welcome";
import Services from "./components/Services";
import GoodThings from "./components/Good-things";
import About from "./components/About";
import Comments from "./components/Comments";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  const aboutRef = useRef<HTMLDivElement>(null);
  const contantRef = useRef<HTMLDivElement>(null);
  const commentsRef = useRef<HTMLDivElement>(null);
  const servicesRef = useRef<HTMLDivElement>(null);

  const scrollToAbout = () => {
    aboutRef.current && aboutRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const scrollToContant = () => {
    contantRef.current && contantRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scrollToComments = () => {
    commentsRef.current && commentsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scrollToServices = () => {
    servicesRef.current && servicesRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  return (
    <>
      <header className="header">
        <img className="logo" src={logo} alt="Logo" />
      </header>
      <Welcome />
      <Services ref={servicesRef}/>
      <GoodThings />
      <About ref={aboutRef} />
      <Comments ref={commentsRef} />
      <Contact ref={contantRef} />
      <Footer
        scrollToAbout={scrollToAbout}
        scrollToComments={scrollToComments}
        scrollToContant={scrollToContant}
        scrollToServices={scrollToServices}
      />
    </>
  );
}

export default App;
