import { Button } from '@mui/material';
import dr from '../images/dr.jpg'

const Welcome = () => {
    return (
        <div className="welcome-page bg-light-blue">

            <div className="split-welcome">
                <div className="text-button-div">
                    <h1 className='dark-blue haed'>מרפאת בוטיק</h1>
                    <p className='dark-blue head-2'>
                        טיפולי שיניים מורכבים,<br />
                        הזרקות בוטוקס<br />
                        וחומצה היאלרונית
                    </p>
                    <Button className="btn" variant="contained" target="_blank" href="https://api.whatsapp.com/send/?phone=972586455011&text=%D7%90%D7%A9%D7%9E%D7%97+%D7%9C%D7%A7%D7%91%D7%95%D7%A2+%D7%AA%D7%95%D7%A8+%D7%9C%D7%9E%D7%A8%D7%A4%D7%90%D7%94%2C+%D7%AA%D7%95%D7%93%D7%94&type=phone_number&app_absent=0', '_blank">לייעוץ ובדיקה חינם</Button>
                    <p className='text-blue text'>
                        מרפאתנו מלווה את לקוחותינו לאורך כל הטיפול,<br />
                        עם זמינות גבוהה, מענה טלפוני והודעות ווטסאפ
                    </p>
                </div>
                <div className='div-img-welcome'>
                    <img className='dr-img' src={dr} alt="דוקטור ברוך במרפאה" />
                </div>
            </div>
        </div>
    );
};

export default Welcome;