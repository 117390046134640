import { Button } from "@mui/material";
import logo from "../images/logo-footer.png";

interface FooterProps {
  scrollToAbout: () => void;
  scrollToComments: () => void;
  scrollToContant: () => void;
  scrollToServices: () => void;
}

const Footer: React.FC<FooterProps> = ({
  scrollToAbout,
  scrollToComments,
  scrollToContant,
  scrollToServices,
}) => {
  return (
    <footer>
      <div className="footer-bg">
        <div className="footer-split">
          <div className="col-footer">
            <h2>קישורים מהירים</h2>
            <p onClick={scrollToAbout}>קצת על המרפאה</p>
            <p onClick={scrollToContant}>יצירת קשר</p>
            <p onClick={scrollToComments}>לקוחות מספרים</p>
            <p
              onClick={() =>
                window.open(
                  "https://www.facebook.com/profile.php?id=100054260270359&mibextid=ZbWKwL"
                )
              }
            >
              דף הפייסבוק שלנו
            </p>
            <p
              onClick={() =>
                window.open("https://www.youtube.com/@dr-borohov-baruch/videos")
              }
            >
              ערוץ היוטיוב שלנו
            </p>
          </div>

          <div className="col-footer">
            <h2>שירותים</h2>
            <p onClick={scrollToServices}>השתלות</p>
            <p onClick={scrollToServices}>אסתטיקה דנטלית</p>
            <p onClick={scrollToServices}>שיקום הפה</p>
            <p onClick={scrollToServices}>רפואת שיניים משמרת</p>
            <p onClick={scrollToServices}>אסתטיקת פנים</p>
          </div>

          <div className="img-col">
            <img className="logo-footer" src={logo} alt="Logo" />
            <Button
              className="btn btn-footer"
              variant="contained"
              target="_blank"
              href="https://api.whatsapp.com/send/?phone=972586455011&text=%D7%90%D7%A9%D7%9E%D7%97+%D7%9C%D7%A7%D7%91%D7%95%D7%A2+%D7%AA%D7%95%D7%A8+%D7%9C%D7%9E%D7%A8%D7%A4%D7%90%D7%94%2C+%D7%AA%D7%95%D7%93%D7%94&type=phone_number&app_absent=0'"
            >
              לייעוץ ובדיקה חינם
            </Button>

            <p>
              מרפאתנו מלווה את לקוחותינו לאורך כל הטיפול, עם זמינות גבוהה ומענה
              טלפוני והודעות ווטסאפ
            </p>
          </div>
        </div>
      </div>

      <div className="bg-footer-bottom">
        <div className="footer-bottom">
          <p className="p-footer-bootom">
            כל הזכויות שמורות ד"ר בורוכוב ברוך 2024 ©
          </p>

          <div className="svg-div">
            <svg
              onClick={() =>
                window.open("https://www.youtube.com/@dr-borohov-baruch/videos")
              }
              id="smart_display_black_24dp"
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 45 45"
            >
              <g id="Group_22" data-name="Group 22">
                <rect
                  id="Rectangle_16"
                  data-name="Rectangle 16"
                  width="45"
                  height="45"
                  fill="none"
                />
              </g>
              <g
                id="Group_23"
                data-name="Group 23"
                transform="translate(3.75 7.5)"
              >
                <path
                  id="Path_17"
                  data-name="Path 17"
                  d="M35.75,4h-30A3.761,3.761,0,0,0,2,7.75v22.5A3.761,3.761,0,0,0,5.75,34h30a3.761,3.761,0,0,0,3.75-3.75V7.75A3.761,3.761,0,0,0,35.75,4ZM16.063,27.438V10.563L29.188,19Z"
                  transform="translate(-2 -4)"
                  fill="#fff"
                />
              </g>
            </svg>

            <svg
              onClick={() =>
                window.open(
                  "https://www.facebook.com/profile.php?id=100054260270359&mibextid=ZbWKwL"
                )
              }
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 45 45"
            >
              <g
                id="Group_45"
                data-name="Group 45"
                transform="translate(-750 -6270)"
              >
                <rect
                  id="Rectangle_33"
                  data-name="Rectangle 33"
                  width="45"
                  height="45"
                  transform="translate(750 6270)"
                  fill="#fff"
                  opacity="0"
                />
                <path
                  id="Icon_awesome-facebook"
                  data-name="Icon awesome-facebook"
                  d="M34.312,17.543A16.875,16.875,0,1,0,14.8,34.317V22.451H10.514V17.543H14.8V13.8c0-4.255,2.518-6.606,6.374-6.606a25.814,25.814,0,0,1,3.778.331V11.7H22.824a2.447,2.447,0,0,0-2.75,2.652v3.187h4.68l-.748,4.908H20.074V34.317A16.954,16.954,0,0,0,34.312,17.543Z"
                  transform="translate(755.063 6274.935)"
                  fill="#fff"
                />
              </g>
            </svg>

            <svg
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send/?phone=972586455011&text=%D7%90%D7%A9%D7%9E%D7%97+%D7%9C%D7%A7%D7%91%D7%95%D7%A2+%D7%AA%D7%95%D7%A8+%D7%9C%D7%9E%D7%A8%D7%A4%D7%90%D7%94%2C+%D7%AA%D7%95%D7%93%D7%94&type=phone_number&app_absent=0"
                )
              }
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              viewBox="0 0 45 45"
            >
              <g
                id="Group_44"
                data-name="Group 44"
                transform="translate(-615 -6268)"
              >
                <rect
                  id="Rectangle_32"
                  data-name="Rectangle 32"
                  width="45"
                  height="45"
                  transform="translate(615 6268)"
                  fill="#345365"
                  opacity="0"
                />
                <path
                  id="Icon_awesome-whatsapp"
                  data-name="Icon awesome-whatsapp"
                  d="M28.7,7.154A16.73,16.73,0,0,0,2.373,27.336L0,36l8.867-2.328a16.673,16.673,0,0,0,7.993,2.034h.008A16.885,16.885,0,0,0,33.75,18.982,16.79,16.79,0,0,0,28.7,7.154ZM16.867,32.889a13.876,13.876,0,0,1-7.081-1.936l-.5-.3L4.023,32.03l1.4-5.13-.331-.527a13.928,13.928,0,1,1,25.832-7.39A14.056,14.056,0,0,1,16.867,32.889Zm7.624-10.411c-.414-.211-2.471-1.22-2.855-1.356s-.663-.211-.942.211-1.077,1.356-1.326,1.642-.49.316-.9.105c-2.456-1.228-4.068-2.192-5.688-4.972-.429-.738.429-.686,1.228-2.283a.774.774,0,0,0-.038-.731c-.105-.211-.942-2.268-1.288-3.1-.339-.814-.686-.7-.942-.716-.241-.015-.52-.015-.8-.015a1.548,1.548,0,0,0-1.115.52,4.7,4.7,0,0,0-1.461,3.488,8.187,8.187,0,0,0,1.7,4.324c.211.279,2.946,4.5,7.142,6.313,2.652,1.145,3.691,1.243,5.017,1.047a4.28,4.28,0,0,0,2.818-1.989,3.5,3.5,0,0,0,.241-1.989C25.184,22.786,24.906,22.681,24.491,22.477Z"
                  transform="translate(621 6271.75)"
                  fill="#fff"
                />
              </g>
            </svg>

            <svg
              onClick={() => window.open("mailto:baruchborohov@gmail.com")}
              width="45"
              height="45"
              viewBox="0 0 45 45"
            >
              <path
                id="Path_13"
                data-name="Path 13"
                d="M0,0H45V45H0Z"
                fill="none"
              />
              <path
                id="Path_14"
                data-name="Path 14"
                d="M20.75,2a18.75,18.75,0,0,0,0,37.5h9.375V35.75H20.75a15.192,15.192,0,0,1-15-15,15.192,15.192,0,0,1,15-15,15.192,15.192,0,0,1,15,15v2.681a3.031,3.031,0,0,1-2.812,2.944,3.031,3.031,0,0,1-2.812-2.944V20.75a9.378,9.378,0,1,0-2.738,6.619,6.943,6.943,0,0,0,5.55,2.756A6.587,6.587,0,0,0,39.5,23.431V20.75A18.757,18.757,0,0,0,20.75,2Zm0,24.375a5.625,5.625,0,1,1,5.625-5.625A5.617,5.617,0,0,1,20.75,26.375Z"
                transform="translate(1.75 1.75)"
                fill="#fff"
              />
            </svg>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
